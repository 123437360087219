import './Contacts.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation } from 'swiper'
// import { SwiperControls } from './SwiperControls'
import 'swiper/swiper-bundle.min.css'

import { YandexMap } from 'src/components'
import { vkMetrics, yandexMetrics } from 'src/analytics'
import { shuffleArray } from 'src/utils'

import { PREVIEW_SLIDES, club } from 'src/data'
const IMAGES = [...shuffleArray(PREVIEW_SLIDES)]

const Rating = ({ rating }) => {
    const coloredStartCount = Math.round(rating)
    const uncoloredStarsCount = 5 - coloredStartCount

    const coloredStars = new Array(coloredStartCount).fill('').map((_, index) => {
        return (
            <svg key={index + '_colored_star'} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                <path fill='var(--orange)' fillRule="evenodd" clipRule="evenodd" d="M9.00085 1.59236C9.4347 0.911887 10.1888 0.5 10.9997 0.5C11.8106 0.5 12.5656 0.911887 12.9994 1.59236L15.4006 5.35651C15.5079 5.52487 15.6767 5.64624 15.8715 5.69557L20.2195 6.79968C21.0052 6.99936 21.6339 7.5843 21.884 8.35013C22.1348 9.11518 21.9731 9.95539 21.4556 10.5748L18.5914 14.0054C18.4628 14.1588 18.3981 14.3554 18.4115 14.5551L18.6971 19.0012C18.7492 19.8047 18.3832 20.5783 17.7268 21.0513C17.0705 21.5243 16.2162 21.6315 15.4621 21.334L11.2908 19.6903C11.1039 19.6167 10.8956 19.6167 10.7087 19.6903L6.53735 21.334C5.78402 21.6315 4.92893 21.5243 4.27341 21.0513C3.61711 20.5783 3.25109 19.8047 3.30237 19.0012L3.58871 14.5551C3.60133 14.3554 3.53665 14.1588 3.40886 14.0054L0.544631 10.5748C0.0271613 9.95539 -0.134548 9.11518 0.11551 8.35013C0.366357 7.5843 0.994263 6.99936 1.77993 6.79968L6.12873 5.69557C6.32357 5.64624 6.49159 5.52487 6.59966 5.35651L9.00085 1.59236Z" />
            </svg>
        )
    })

    const uncoloredStars = new Array(uncoloredStarsCount).fill('').map((_, index) => {
        return (
            <svg key={index + '_colored_star'} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                <path fill='var(--white)' fillRule="evenodd" clipRule="evenodd" d="M9.00085 1.59236C9.4347 0.911887 10.1888 0.5 10.9997 0.5C11.8106 0.5 12.5656 0.911887 12.9994 1.59236L15.4006 5.35651C15.5079 5.52487 15.6767 5.64624 15.8715 5.69557L20.2195 6.79968C21.0052 6.99936 21.6339 7.5843 21.884 8.35013C22.1348 9.11518 21.9731 9.95539 21.4556 10.5748L18.5914 14.0054C18.4628 14.1588 18.3981 14.3554 18.4115 14.5551L18.6971 19.0012C18.7492 19.8047 18.3832 20.5783 17.7268 21.0513C17.0705 21.5243 16.2162 21.6315 15.4621 21.334L11.2908 19.6903C11.1039 19.6167 10.8956 19.6167 10.7087 19.6903L6.53735 21.334C5.78402 21.6315 4.92893 21.5243 4.27341 21.0513C3.61711 20.5783 3.25109 19.8047 3.30237 19.0012L3.58871 14.5551C3.60133 14.3554 3.53665 14.1588 3.40886 14.0054L0.544631 10.5748C0.0271613 9.95539 -0.134548 9.11518 0.11551 8.35013C0.366357 7.5843 0.994263 6.99936 1.77993 6.79968L6.12873 5.69557C6.32357 5.64624 6.49159 5.52487 6.59966 5.35651L9.00085 1.59236Z" />
            </svg>
        )
    })

    return (
        <div className='rating__stars'>
            {coloredStars}
            {uncoloredStars}
        </div>
    )
}

export const Contacts = ({ openGallery }) => {
    const handleAnalytics = () => {
        yandexMetrics('btn-click-light-clubs')
        vkMetrics('btn-click-light-clubs')
    }

    return (
        <section id='contact' className='contacts section-mt-100 container section-grid scroll-mt'>
            <div className='contacts__left'>
                <div className='contacts__title title'>
                    <p className='title__sub-text'>КОНТАКТЫ</p>
                    <h2 className='title__main-text'>{club.name}</h2>
                </div>

                <div className='contacts__rating rating'>
                    <div className='rating__title'>
                        <p>Рейтинг клуба:</p>
                        <a
                            target='_blank'
                            rel='noreferrer'
                            href={club.reviews_link}
                        >
                            {club.reviews}
                        </a>
                    </div>

                    <div className='rating__body'>
                        <Rating rating={club.rating_number} />
                        <p>({club.rating})</p>
                    </div>
                </div>

                <div className='contacts__address'>
                    <div>
                        <p>{club.address}</p>
                        <a className='contacts__route' target='_blank' rel='noreferrer' href={club.route}>Проложить маршрут</a>
                    </div>

                    <a href={club.phone_link}>{club.phone}</a>

                    <p>{club.schedule}</p>
                </div>

                <div className='contacts__another-club'>
                    <h3>НЕ ПОДХОДИТ РАСПОЛОЖЕНИЕ&nbsp;?</h3>
                    <p>У нас {club.clubs_total} клубов – выбери подходящий тебе!</p>
                    <a href={club.clubs_link_with_utms} target='_blank' rel='noreferrer'>
                        <button onClick={handleAnalytics} className='btn btn--light'>ВЫБРАТЬ ДРУГОЙ КЛУБ</button>
                    </a>
                </div>
            </div>

            <div className='contacts__right scroll-mt' id='yandex-map'>
                <div className='contacts__map' id='map'>
                    <YandexMap coordinates={club.coordinates} />
                </div>

                <Swiper
                    key='contacts-slider'
                    slidesPerView={'auto'}
                    spaceBetween={10}
                    // autoplay={{
                    //     delay: 3000,
                    //     disableOnInteraction: false,
                    // }}
                    loop={false}
                    modules={[Autoplay, Navigation]}
                    className='contacts__gallery'
                    breakpoints={{
                        1000: {
                            direction: 'vertical'
                        },
                        300: {
                            direction: 'horizontal'
                        }
                        // className={style.swiper_slide}
                    }}
                >
                    {IMAGES.map((item, idx) => {
                        return (
                            <SwiperSlide key={item.id + idx} >
                                <div className='contacts__photo'>
                                    <img src={item.image} alt='gallery' />
                                    <button onClick={() => openGallery('Галерея', item.id)}>Открыть</button>
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
        </section>
    )
}