import { club } from 'src/data'

export const yandexMetrics = (goal) => {
    if (goal && window.ym) {
        window?.ym(club.ym_code, 'reachGoal', goal)
    }
}

export const vkMetrics = (goal) => {
    if (goal && window._tmr) {
        window._tmr.push({ type: 'reachGoal', id: club.vk_code, goal })
    }
}

// yandex

// ym(metrics_code,'reachGoal','zvonok')

// Клик по кнопке "Купить", тариф Light
// ym(metrics_code, 'reachGoal', 'btn-buy-click-light')

// Клик по кнопке "Купить", тариф Smart
// ym(metrics_code, 'reachGoal', 'btn-buy-click-smart')

// Клик по кнопке "Купить", тариф Infinity
// ym(metrics_code, 'reachGoal', 'btn-buy-click-infinity')

// Клик по кнопке "Оставить заявку", Шапка
// ym(metrics_code, 'reachGoal', 'btn-lead-click-head')

// Клик по кнопке "Оставить заявку", О DDX(2 экран)
// ym(metrics_code, 'reachGoal', 'btn-lead-click-aboutDDX')

// Клик по кнопке "Подробнее", Зоны клуба
// ym(metrics_code, 'reachGoal', 'btn-more-click-zone')

// Клик по кнопке "Другой клуб", Контакты
// ym(metrics_code,'reachGoal','btn-click-light-clubs')

// Клик по кнопке "Задать вопрос", Еще не с нами
// ym(metrics_code,'reachGoal','btn-question-click')

// Отправлена форма "Оставить заявку"
// ym(metrics_code,'reachGoal',' callback-form-lead')

// Отправлена форма "Задать вопрос"
// ym(metrics_code,'reachGoal',' callback2-form-lead')

// vk

// Клик по кнопке "Купить", тариф Light
// _tmr.push({ type: 'reachGoal', id: metrics_code, goal: 'btn-buy-click-light'});

// Клик по кнопке "Купить", тариф Smart
// _tmr.push({ type: 'reachGoal', id: metrics_code, goal: 'btn-buy-click-smart'});

// Клик по кнопке "Купить", тариф Infinity
// _tmr.push({ type: 'reachGoal', id: metrics_code, goal: 'btn-buy-click-infinity'});

// Клик по кнопке "Оставить заявку", Шапка
// _tmr.push({ type: 'reachGoal', id: metrics_code, goal: 'btn-lead-click-head'});

// Клик по кнопке "Оставить заявку", О DDX (2 экран)
// _tmr.push({ type: 'reachGoal', id: metrics_code, goal: 'btn-lead-click-aboutDDX'});

// Клик по кнопке "Подробнее", Зоны клуба
// _tmr.push({ type: 'reachGoal', id: metrics_code, goal: 'btn-more-click-zone'});

// Клик по кнопке "Другой клуб", Контакты
// _tmr.push({ type: 'reachGoal', id: metrics_code, goal: 'btn-click-light-clubs'});

// Клик по кнопке "Задать вопрос", Еще не с нами
// _tmr.push({ type: 'reachGoal', id: metrics_code, goal: 'btn-question-click'});

// Отправлена форма "Оставить заявку"
// _tmr.push({ type: 'reachGoal', id: metrics_code, goal: 'callback-form-lead'});

// Отправлена форма "Задать вопрос"
// _tmr.push({ type: 'reachGoal', id: metrics_code, goal: 'callback2-form-lead'});