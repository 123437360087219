import { Checkbox, PhoneInput } from 'src/components'
import './LeadModal.scss'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { validatePhone } from 'src/utils'
import { useEffect, useState } from 'react'
import { vkMetrics, yandexMetrics } from 'src/analytics'
import { useSearchParams } from 'react-router-dom'

const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']

export const LeaveLead = ({ onSuccess, onClose, tariff }) => {
    const [params] = useSearchParams()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const tariffNameCapitalized = tariff.charAt(0).toUpperCase() + tariff.slice(1).toLowerCase()

    const formik = useFormik({
        initialValues: { name: '', phone: '', personalData: true, rim: true },
        validationSchema: Yup.object({
            name: Yup.string().trim().required().min(1).max(100),
            phone: Yup.string().required().test('invalid phone/email', 'invalid phone/email', validatePhone),
            personalData: Yup.bool().test('invalid value', 'invalid value', val => val),
            rim: Yup.bool(),
        }),
        onSubmit: async (values, actions) => {
            const data = {
                name: values.name.trim(),
                phone: values.phone.replace(/[^\d]/g, ''),
                // email: isEmail ? values.phoneOrEmail.trim() : null,
                personalData: values.personalData,
                rim: values.rim,
                tariff: tariffNameCapitalized,
                // club: 'Авиапарк',
            }

            const utms = {}
            utmParams.forEach(param => {
                const value = params.get(param)
                if (value) {
                    utms[param] = value
                }
            })

            setError('')
            setLoading(true)

            try {
                const response = await fetch('https://api.play-ddx.ru/api/ddxfitness-pro/lead', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json;charset=utf-8' },
                    body: JSON.stringify({ ...data, ...utms })
                })
                if (response.status !== 200) {
                    setError('При отправке данных произошла ошибка')
                    return
                }
                actions.resetForm()
                yandexMetrics('callback-form-lead')
                vkMetrics('callback-form-lead')
                onSuccess()
            } catch (e) {
                setError('При отправке данных произошла ошибка')
            } finally {
                setLoading(false)
            }
        },
    })

    useEffect(() => {
        document.getElementsByName("name")[0].focus()

        // following is optional
        return () => {
            // formik.resetForm()
        }
    }, [])

    const isBtnDisabled = Object.keys(formik.errors).length || loading

    return (
        <div className='form-modal'>
            <button onClick={onClose} className='form-modal__close-btn'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M15 1L1 15M1 1L15 15" stroke="#656875" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </button>

            <div className='title'>
                <h2 className='title__main-text'>Оставить заявку</h2>
            </div>

            <form className='form-modal__form  form' onSubmit={formik.handleSubmit}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                    <input
                        className=''
                        tabIndex={0}
                        error={(formik.errors.name && formik.touched.name) && 'true'}
                        type='text' placeholder='Имя' name="name" id="name"
                        onChange={formik.handleChange} onBlur={formik.handleBlur}
                        value={formik.values.name} disabled={loading}
                    />
                    <PhoneInput
                        className='input'
                        tabIndex={0}
                        error={(formik.errors.phone && formik.touched.phone) && 'true'}
                        type='text' placeholder='Телефон' name="phone" id="phone"
                        onChange={formik.handleChange} onBlur={formik.handleBlur}
                        value={formik.values.phone} disabled={loading}
                    />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                    <Checkbox
                        name="personalData" id='personalData' checked={formik.values.personalData}
                        onClick={() => formik.setFieldValue('personalData', !formik.values.personalData)}
                        disabled={loading}
                    >
                        Я согласен с <a href='https://www.ddxfitness.ru/policy/' target='_blank' rel='noreferrer'>политикой обработки персональных данных</a>
                    </Checkbox>
                    <Checkbox
                        name="rim" id='rim' checked={formik.values.rim}
                        onClick={() => formik.setFieldValue('rim', !formik.values.rim)}
                        disabled={loading}
                    >
                        Я согласен(а) на <a href='https://www.ddxfitness.ru/consent-advertising/' target='_blank' rel='noreferrer'>рассылку новостей и предложений DDX Fitness</a>
                    </Checkbox>
                </div>

                <div>
                    <button
                        // tabIndex={0}
                        type='submit'
                        className='btn btn--light'
                        disabled={isBtnDisabled}
                    >
                        Отправить заявку
                    </button>
                </div>
                {error && 
                    <p
                        style={{ color: 'rgba(212, 9, 9, 0.7)', fontFamily: 'Ubuntu', fontSize: 14 }}
                    >{error}</p>}
            </form>

        </div>
    )
}