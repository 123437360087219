import { useRef, useState } from 'react'
import { ProgressButton } from 'src/components'

import { vkMetrics, yandexMetrics } from 'src/analytics'
import { HERO_SLIDES as SLIDES, club } from 'src/data'
import ym_icon from './images/ym_icon.png'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, EffectFade } from 'swiper'
import 'swiper/css'
import 'swiper/css/effect-fade'
import './Hero.scss'

const AUTO_SLIDE_DELAY = 4000

export const Hero = () => {
    const swiperElement = useRef(null)
    const [activeSlideIndex, setActiveSlideIndex] = useState(0)
    const onAutoplayTimeLeft = (s, time, progress) => {
        const selector = '#hero-slide-progress-circle-' + activeSlideIndex
        const progressCircleElement = document.querySelector(selector)
        progressCircleElement?.style?.setProperty('--progress', 1 - progress)
    }

    const handleOnSlideChange = (event) => {
        setActiveSlideIndex(event.realIndex)
    }

    const progressButtons = SLIDES.map((_, index) => {
        return (
            <ProgressButton
                key={index}
                id={'hero-slide-progress-circle-' + index}
                onClick={() => swiperElement.current?.swiper?.slideToLoop(index)}
                active={AUTO_SLIDE_DELAY && activeSlideIndex === index}
            />
        )
    })

    const heroSlides = SLIDES.map((currnetSlide, index) => {
        return (
            <SwiperSlide key={index}>
                <div
                    className='hero__image'
                    style={{ backgroundImage: `url(${currnetSlide})` }}
                ></div>
            </SwiperSlide>
        )
    })

    const handleAnalytics = () => {
        yandexMetrics('btn-lead-click-head')
        vkMetrics('btn-lead-click-head')
    }

    return (
        <section id='start' className='hero scroll-mt'>
            <Swiper
                id='hero-swiper'
                className='hero__swiper'
                ref={swiperElement}
                slidesPerView='auto'
                spaceBetween={0}
                speed={700}
                effect='fade'
                autoplay={{
                    delay: AUTO_SLIDE_DELAY,
                    disableOnInteraction: false,
                }}
                allowTouchMove={false}
                onAutoplayTimeLeft={onAutoplayTimeLeft}
                loop={true}
                modules={[Autoplay, EffectFade]}
                onSlideChange={handleOnSlideChange}
            >
                {heroSlides}
            </Swiper>

            <div className='hero__grid container'>
                <div className='hero__title title title--light title--large'>
                    {/* <p className='title__sub-text'>с 21.12 по 28.12</p> */}
                    <h1 className='title__main-text'>Присоединяйся к DDX Fitness и не плати за год вперед</h1>

                    {/* <h1 style={{ textTransform: 'none' }} className='title__main-text'>DDX Ясенево: скидка 66% на вступительный платёж до 31 октября!</h1> */}
                </div>

                <div className='hero__transparent-block transparent-block'>
                    <p className='transparent-block__text'>Фитнес с помесячной оплатой
                        <span> от 1700&nbsp;руб.</span>
                    </p>
                    <a onClick={handleAnalytics} href="#tariff">
                        <button className='btn'>Выбрать тариф</button>
                    </a>
                </div>

                <div className='hero__footer'>
                    <div className='hero__btns'>
                        <a href='/#yandex-map'>
                            <img src={ym_icon} alt='ym' />
                            <div>{club.name}</div>
                        </a>

                        <a href={club.tour_3d} target='_blank' rel='noreferrer'>
                            <div>
                                3D-тур
                            </div>
                            <svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10' fill='none'>
                                <path d='M1 9L9 1M9 1H1M9 1V9' stroke='white' strokeWidth='2' strokeLinecap='round' />
                            </svg>
                        </a>
                    </div>

                    <div className='hero__controls'>
                        {progressButtons}
                    </div>
                </div>
            </div>
        </section>
    )
}