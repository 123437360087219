import { useRef, useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectFade } from 'swiper'
import 'swiper/css'
import 'swiper/css/effect-fade'
import './GalleryModal.scss'

import { GALLERY_SLIDES as SLIDES, CATEGORIES } from 'src/data'

export const GalleryModal = ({ title, index, onClose }) => {
    const swiperElement = useRef(null)

    const slideIndexById = SLIDES.findIndex(i => i.id === index)
    const initialSlideIndex = slideIndexById !== -1 ? slideIndexById : 0

    const [filterCategory, setFilterCategory] = useState('все зоны')

    const isGallery = title === 'Галерея'

    const slidesFilteredByModalType = isGallery
        ? SLIDES
        : SLIDES.filter((image) => {
            return image.categories.indexOf(title.toLowerCase()) !== -1
        })

    const isAllZones = filterCategory === 'все зоны'
    const slidesFilteredByFilterCategory = isAllZones
        ? slidesFilteredByModalType
        : slidesFilteredByModalType.filter((image) => {
            return image.categories.indexOf(filterCategory.toLowerCase()) !== -1
        })

    const isFilterBtnActive = (name) => filterCategory.toLocaleLowerCase() === name.toLowerCase()
    const filterBtnClassNames = (name) => isFilterBtnActive(name) ? 'gallery-modal__zone-btn gallery-modal__zone-btn--active' : 'gallery-modal__zone-btn'

    const handleFilterSet = (filter) => () => {
        swiperElement.current?.swiper?.slideToLoop(0)
        setFilterCategory(filter)
    }

    const handlePrevSlide = () => swiperElement.current?.swiper?.slidePrev()
    const handleNextSlide = () => swiperElement.current?.swiper?.slideNext()

    const gallerySlides = slidesFilteredByFilterCategory.map((currentSlide, index) => {
        return (
            <SwiperSlide key={index}>
                <div className='gallery-modal__image'>
                    <img
                        src={currentSlide.slide}
                        alt='gallery'
                    />

                    <a className='gallery-modal__zoom-btn' href={currentSlide.slide} target='_blank' rel='noreferrer'>
                        <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
                            <path d='M17.523 15.2212L14.9257 12.6238C15.779 11.3367 16.279 9.79589 16.279 8.1395C16.279 3.65138 12.6276 0 8.1395 0C3.65138 0 0 3.65138 0 8.1395C0 12.6276 3.65138 16.279 8.1395 16.279C9.79589 16.279 11.3367 15.779 12.6238 14.9257L15.2212 17.523C15.8563 18.159 16.8879 18.159 17.523 17.523C18.159 16.8876 18.159 15.8566 17.523 15.2212ZM2.44185 8.1395C2.44185 4.99765 4.99765 2.44185 8.1395 2.44185C11.2813 2.44185 13.8372 4.99765 13.8372 8.1395C13.8372 11.2813 11.2813 13.8372 8.1395 13.8372C4.99765 13.8372 2.44185 11.2813 2.44185 8.1395Z' fill='white' />
                            <rect x='7.04688' y='4.87598' width='2.17053' height='6.5116' rx='1.08527' fill='white' />
                            <rect x='11.3877' y='7.04663' width='2.17053' height='6.5116' rx='1.08527' transform='rotate(90 11.3877 7.04663)' fill='white' />
                        </svg>
                    </a>
                </div>
            </SwiperSlide>
        )
    })

    const isFilter = CATEGORIES[title.toLowerCase()]
    const filterCategories = isFilter.map((currentFilter, index) => {
        return (
            <button
                key={index}
                onClick={handleFilterSet(currentFilter.toLowerCase())}
                className={filterBtnClassNames(currentFilter.toLowerCase())}
                type='button'
            >
                {currentFilter}
            </button>
        )})

    return (
        <div className='gallery-modal'>
            <div onClick={onClose} className='gallery-modal__close-btn'>
                <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
                    <path d='M15 1L1 15M1 1L15 15' stroke='#656875' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
                </svg>
            </div>

            <div className='title'>
                <h2 className='title__main-text'>{title}</h2>
            </div>

            {isFilter &&
                <div className='gallery-modal__zones'>
                    <div className='gallery-modal__zones-container'>
                        {filterCategories}
                    </div>
                </div>}

            <Swiper
                id='gallery-swiper'
                key={filterCategory}
                initialSlide={initialSlideIndex || 0}
                className='gallery-modal__swiper'
                ref={swiperElement}
                slidesPerView='auto'
                spaceBetween={0}
                speed={300}
                effect='fade'
                loop={true}
                modules={[EffectFade]}
            >
                {gallerySlides}

                <div
                    className='gallery-modal__controls'
                >
                    <button
                        onClick={handlePrevSlide}
                        type='button'
                    >
                        <svg width='9' height='14' viewBox='0 0 9 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M8 1L2 7L8 13' strokeWidth='2' strokeLinecap='round' />
                        </svg>
                    </button>

                    <button
                        onClick={handleNextSlide}
                        type='button'
                    >
                        <svg width='9' height='14' viewBox='0 0 9 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M0.999999 1L7 7L0.999999 13' strokeWidth='2' strokeLinecap='round' />
                        </svg>
                    </button>
                </div>
            </Swiper>
        </div>
    )
}