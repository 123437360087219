import { useEffect, useState } from 'react'
import './Reasons.scss'

import smileImage from './images/smile.png'
import { useWindowWidth } from 'src/hooks'

const INTERSECTION_TOP_MARGIN = 300

export const Reasons = () => {
    const [selectedItem, setSelectedItem] = useState(0)
    const windowWidth = useWindowWidth()
    const isMobile = windowWidth <= 600

    const reasonsItemClassNames = (id) => selectedItem === id
        ? 'reasons__item reasons__item--active'
        : 'reasons__item'
    const handleReasonsItemHover = (id) => {
        if (!isMobile) {
            return () => setSelectedItem(id)
        }
    }

    useEffect(() => {
        setSelectedItem(0)
        
        const containerObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.intersectionRect.top === INTERSECTION_TOP_MARGIN && isMobile) {
                        setSelectedItem(+entry.target.id)
                        // console.log('container__item вошел в область container__observer', +entry.target.id, entry.intersectionRect.top, entry)
                    } else {
                        // console.log('container__item вышел из области container__observer', +entry.target.id, entry)

                    }
                })
            },
            {
                root: null,
                rootMargin: `-${INTERSECTION_TOP_MARGIN}px 0px 0px 0px`,
                threshold: 0.5,
            }
        )

        // Начинаем отслеживать каждый элемент "container__item"
        const containerItems = document.querySelectorAll('.reasons__item')
        containerItems.forEach((item) => {
            containerObserver.observe(item)
        })

        return () => {
            containerItems.forEach((item) => {
                containerObserver.unobserve(item)
            })
        }
    }, [isMobile])


    return (
        <section className='reasons section-mt-100 container section-grid'>
            <div className='reasons__left'>
                <div className='reasons__title title'>
                    <p className='title__sub-text'>ДЛЯ ЧЕГО НУЖЕН ФИТНЕС</p>
                    <h2 className='title__main-text'>Какие задачи помогает решить
                        <span>  посещение Фитнес-клуба</span>
                    </h2>
                </div>
            </div>

            <div className='reasons__right'>
                <div className='reasons__bar'></div>

                <div className='reasons__list'>
                    <div
                        className={reasonsItemClassNames(0)}
                        id={0}
                    >
                        <div onMouseEnter={handleReasonsItemHover(0)} className='reasons__hover-block'></div>
                        <img src={smileImage} alt='' />
                        <h3>Улучшить самочувствие</h3>
                        <p>Избавься от болей в спине и зажатости мышц.</p>
                    </div>

                    <div

                        className={reasonsItemClassNames(1)}
                        id={1}
                    >
                        <div onMouseEnter={handleReasonsItemHover(1)} className='reasons__hover-block'></div>
                        <img src={smileImage} alt='' />
                        <h3>Побороть стресс</h3>
                        <p>Подготовь свой организм к вызовам городской жизни.</p>
                    </div>

                    <div
                        className={reasonsItemClassNames(2)}
                        id={2}
                    >
                        <div onMouseEnter={handleReasonsItemHover(2)} className='reasons__hover-block'></div>
                        <img src={smileImage} alt='' />
                        <h3>Нормализовать сон</h3>
                        <p>Успокой сердце и голову на мягких уравновешивающих тренировках.</p>
                    </div>

                    <div
                        className={reasonsItemClassNames(3)}
                        id={3}
                    >
                        <div onMouseEnter={handleReasonsItemHover(3)} className='reasons__hover-block'></div>
                        <img src={smileImage} alt='' />
                        <h3>Зарядиться энергией</h3>
                        <p>Больше никаких засыпаний на рабочих созвонах! Сохраняй бодрость до самого вечера.</p>
                    </div>

                    <div
                        className={reasonsItemClassNames(4)}
                        id={4}
                    >
                        <div onMouseEnter={handleReasonsItemHover(4)} className='reasons__hover-block'></div>
                        <img src={smileImage} alt='' />
                        <h3>Повысить качество жизни</h3>
                        <p>Усиль свою мотивацию и увеличь силы для ежедневных великих свершений.</p>
                    </div>
                </div>
            </div>


        </section>
    )
}