import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import { Autoplay, Navigation } from 'swiper'
import 'swiper/swiper-bundle.min.css'

import './About.scss'

import cardImage01 from './images/about_01.png'
import cardImage02 from './images/about_02.png'
import cardImage03 from './images/about_03.png'
import cardImage04 from './images/about_04.png'
import cardImage05 from './images/about_05.png'
import { vkMetrics, yandexMetrics } from 'src/analytics'
// import { useIntersection } from 'src/hooks'

const AUTO_SLIDE = true
const autoslideOptions = AUTO_SLIDE ? {
    delay: 3000,
    disableOnInteraction: false,
} : false

const aboutFeatures = [
    { id: 1, title: 'СОВРЕМЕННЫЕ ЗАЛЫ', text: 'Наши клубы оборудованы не только топовыми тренажёрами, но и зеркалами, чтобы делать фото для соцсетей.', image: cardImage01 },
    { id: 2, title: 'ПРЕМИУМ-ОБОРУДОВАНИЕ', text: 'Биомеханика и безопасность – за это Technogym выбираем и мы, и Олимпийские спортсмены.', image: cardImage02 },
    { id: 3, title: 'Клуб в твоём районе', text: 'Более 38 клубов по всей России. DDX всегда рядом.', image: cardImage03 },
    { id: 4, title: 'Мультидоступ', text: 'На тарифе Infinity посещай любой клуб сети в удобное время.', image: cardImage04 },
    { id: 5, title: 'Семейный доступ', text: 'Оформи абонемент и тренируйся по одному браслету с родственниками.', image: cardImage05 },
    { id: 6, title: 'СОВРЕМЕННЫЕ ЗАЛЫ', text: 'Наши клубы оборудованы не только топовыми тренажёрами, но и зеркалами, чтобы делать фото для соцсетей.', image: cardImage01 },
    { id: 7, title: 'ПРЕМИУМ-ОБОРУДОВАНИЕ', text: 'Биомеханика и безопасность – за это Technogym выбираем и мы, и Олимпийские спортсмены.', image: cardImage02 },
    { id: 8, title: 'Клуб в твоём районе', text: 'Более 38 клубов по всей России. DDX всегда рядом.', image: cardImage03 },
    { id: 9, title: 'Мультидоступ', text: 'На тарифе Infinity посещай любой клуб сети в удобное время.', image: cardImage04 },
    { id: 10, title: 'Семейный доступ', text: 'Оформи абонемент и тренируйся по одному браслету с родственниками.', image: cardImage05 },
]

const AboutSwiperControls = () => {
    const swiper = useSwiper()
    return (
        <div className='about__swiper-controls'>
            <button onClick={() => swiper.slidePrev()}>
                <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 1L2 7L8 13" strokeWidth="2" strokeLinecap="round" />
                </svg>
            </button>

            <button onClick={() => swiper.slideNext()}>
                <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.999999 1L7 7L0.999999 13" strokeWidth="2" strokeLinecap="round" />
                </svg>
            </button>
        </div>
    )
}

export const About = () => {
    const handleAnalytics = () => {
        yandexMetrics('btn-lead-click-aboutDDX')
        vkMetrics('btn-lead-click-aboutDDX')
    }

    // const [targetRef, isVisible] = useIntersection({
    //     root: null,
    //     rootMargin: '0px',
    //     threshold: 0.5,
    // })

    // const autoslideOptions = AUTO_SLIDE && isVisible ? {
    //     delay: 3000,
    //     disableOnInteraction: false,
    // } : false

    return (
        <section id='about' className='about section-mt-100 container section-grid section-grid--wide scroll-mt'>
            <div className='about__left'>
                <div className='about__title title'>
                    <p className='title__sub-text'>О НАС</p>
                    <h2 className='title__main-text'>ЧТО ТАКОЕ DDX</h2>
                </div>
            </div>

            <div className='about__right'>
                <Swiper
                    key='about-slider'
                    slidesPerView='auto'
                    spaceBetween={40}
                    direction='horizontal'
                    autoplay={autoslideOptions}
                    loop={true}
                    modules={[Autoplay, Navigation]}
                    className='about__cards'
                >
                    {aboutFeatures.map((feature, idx) => {
                        return (
                            <SwiperSlide key={idx} className='about__card' >
                                <img src={feature.image} alt='about' />
                                <h3>{feature.title}</h3>
                                <p>{feature.text}</p>
                            </SwiperSlide>
                        )
                    })}
                    <AboutSwiperControls />
                </Swiper>

                <a onClick={handleAnalytics} href='#tariff' className='about__btn'>
                    <button className='btn btn--light'>ВЫБРАТЬ ТАРИФ</button>
                </a>
            </div>

        </section>
    )
}