import './SlideButton.scss'

export const SlideButton = ({
    active,
    image,
    text,
    ...props
}) => {
    const buttonClassNames = 'slide-btn ' +
        (active ? ' slide-btn--active' : '')

    return (
        <div className={buttonClassNames} {...props}>
            <img src={image} alt='slide'/><p>{text}</p>

            <svg transform='rotate(180)' width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <rect className='slide-btn__circle' x='2' y='2' width='76' height='76' rx='38' stroke='white'strokeWidth='4' />
                <rect className='slide-btn__progress' x='2' y='2' width='76' height='76' strokeDasharray='240' rx='38' strokeWidth='4' />
            </svg>
        </div>
    )
}