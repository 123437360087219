import { Hero } from './Hero/Hero'
import { About } from './About/About'
import { Features } from './Features/Features'
import { Reasons } from './Reasons/Reasons'
import { Tariffs } from './Tariffs/Tariffs'
import { Zones } from './Zones/Zones'
import { Contacts } from './Contacts/Contacts'
import { Join } from './Join/Join'
import { FAQ } from './FAQ/FAQ'
import { useState } from 'react'
import { GalleryModal } from './GalleryModal/GalleryModal'
import { LeaveLead } from './LeadModal/LeaveLead'
import { LeaveQuestion } from './LeadModal/LeaveQuestion'
import { LeadSent } from './LeadModal/LeadSent'

export const Main = () => {
    const [modal, setModal] = useState(false)
    const closeModal = () => setModal(false)
    // const openModal = (params) => setModal(params)

    const openGallery = (title, index) => setModal(<GalleryModal title={title} index={index} onClose={closeModal} />)
    const openThanksModal = () => setModal(<LeadSent onClose={closeModal} />)
    const openLeadForm = (tariff) => setModal(<LeaveLead onSuccess={openThanksModal} onClose={closeModal} tariff={tariff} />)
    const openQuestionForm = () => setModal(<LeaveQuestion onSuccess={openThanksModal} onClose={closeModal} />)

    return (
        <div className='main'>
            {modal &&
                <div className='overlay'>
                    <div className='overlay__scroll-y'>
                        {modal}
                    </div>
                </div>}

            <Hero />
            <About />
            <Features />
            <Reasons />
            <Tariffs openLeadForm={openLeadForm} />
            <Zones openGallery={openGallery}/>
            <Contacts openGallery={openGallery}/>
            <Join openQuestionForm={openQuestionForm} />
            <FAQ />
        </div>
    )
}