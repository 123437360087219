import './Features.scss'

import featuresImage from './images/features.webp'

export const Features = () => {
    return (
        <section className='features section-mt-100 container container--small'>
            <div className='features__grid'>

                <div className='features__title title'>
                    <p className='title__sub-text'>ОСОБЕННОСТИ</p>
                    <h2 className='title__main-text'>У нас комфортно <span>людям любого уровня физической подготовки</span> и опыта: от новичков до завсегдатаев фитнес-залов
                    </h2>
                </div>

                <div className='features__list'>
                    <div className='features__feature'>
                        <h3>ФИТНЕC- <br />ТЕСТИРОВАНИЕ</h3>
                        <p>Тренер оценивает кардио- и физическую выносливость, чтобы подобрать оптимальную нагрузку.</p>
                    </div>

                    <div className='features__feature'>
                        <h3>Обучающие программы SMART START</h3>
                        <p>Тренировки на все группы мышц: тренер покажет правильную технику работы на тренажёрах и подберёт нагрузку.</p>
                    </div>
                </div>

                <img className='features__image' src={featuresImage} alt='features' />

            </div>
        </section>
    )
}