import './Checkbox.scss';

export const Checkbox = ({ children, checked, onClick, tabIndex }) => {
    return (
        <div className='check-box'>
            <input />
            <svg onClick={onClick} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className='check-box__animate-svg' strokeDashoffset={checked ? '0' : '14'} strokeDasharray='14' d="M6.5 10.5714L10.1 14L15.5 8" stroke="#ED692A" strokeWidth="2" strokeLinecap="round" />
                <rect x="0.5" y="0.5" width="21" height="21" rx="3.5" stroke="#DAD1FF" />
            </svg>

            <label style={{ overflowX: 'auto', marginRight: 10 }}>{children}</label>
        </div>
    )
}