import { FAQItem } from './FAQItem'
import './FAQ.scss'

const FAQList = [
    {
        question: 'Есть ли лимит по посещениям клуба?',
        answer: 'Лимита нет. Фитнес-клуб можно посещать ежедневно с 6 до 24.'
    },
    {
        question: 'С какого возраста можно стать членом клуба?',
        answer: 'С 16 лет.'
    },
    {
        question: 'Сколько стоит годовой абонемент?',
        answer: 'У нас помесячная оплата.'
    },
    {
        question: 'Помесячная оплата – это рассрочка?',
        answer: 'Нет, это абонементы с помесячной оплатой. Ты можешь прекратить заниматься и платить в любое время, никаких долговых обязательств.'
    },
    {
        question: 'Все групповые программы бесплатные?',
        answer: 'Все тренировки входят в абонементы Smart и Infinity.'
    },
    {
        question: 'Что такое семейный доступ?',
        answer: 'Это безлимитный доступ в клуб для ближайших родственников клиента. К ним относятся: жена/муж, дочь/сын с 16 лет, мать/отец. Визиты необходимо зарегистрировать и осуществить в будние дни с 10:00 до 17:00, в выходные дни с 10:00 до 22:00. Требуется документальное подтверждение родства. Семейный доступ можно реализовать в любом клубе сети. Доступ родственников осуществляется только при параллельном посещении с действующим членом клуба, носителем тарифа Infinity.'
    },
    {
        question: 'Что такое доступ для друзей?',
        answer: 'На тарифе Infinity у члена клуба есть неограниченное количество бесплатных гостевых визитов для друзей. Их нужно зарегистрировать в будние дни с 10:00 до 17:00, в выходные с 10:00 до 22:00. Гостевой визит для друзей можно реализовать в любом клубе сети. Доступ гостей осуществляется только при параллельном посещении с действующим членом клуба, носителем тарифа Infinity.\n\n*гость может воспользоваться бесплатным гостевым визитом один раз в год.'
    },
]

export const FAQ = () => {
    const faqElements = FAQList.map((currentFAQ, index) => {
        return (
            <FAQItem
                key={index}
                question={currentFAQ.question}
                answer={currentFAQ.answer}
            />
        )
    })

    return (
        <section id='faq' className='faq section-mt-100 container section-grid scroll-mt'>
            <div className='faq__left'>
                <div className='faq__title title'>
                    <p className='title__sub-text'>FAQ</p>
                    <h2 className='title__main-text'>ЧАСТО ЗАДАВАЕМЫЕ
                        <span> ВОПРОСЫ</span>
                    </h2>
                </div>
            </div>

            <div className='faq__right'>
                {faqElements}
            </div>
        </section>
    )
}