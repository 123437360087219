import { useState } from 'react'
import './FAQ.scss'

export const FAQItem = ({
    question,
    answer,
}) => {
    const [isOpened, setOpened] = useState(false)
    const toggleAnswer = () => setOpened(prevState => !prevState)

    const questionAnswerClassNames = isOpened
        ? 'question__answer question__answer--active'
        : 'question__answer' 

    return (
        <div className='faq__question question'>
            <div className='question__header'>
                <p>{question}</p>
                <button onClick={toggleAnswer}>
                    {isOpened ?
                        <svg xmlns='http://www.w3.org/2000/svg' width='12' height='2' viewBox='0 0 12 2' fill='none'>
                            <path d='M0 1C0 0.447715 0.447715 0 1 0H11C11.5523 0 12 0.447715 12 1C12 1.55228 11.5523 2 11 2H1C0.447715 2 0 1.55228 0 1Z' />
                        </svg> :

                        <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
                            <path fillRule='evenodd' clipRule='evenodd' d='M7 1C7 0.447715 6.55228 0 6 0C5.44772 0 5 0.447715 5 1V5H1C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7H5V11C5 11.5523 5.44771 12 6 12C6.55228 12 7 11.5523 7 11V7H11C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5H7V1Z' />
                        </svg>}
                </button>
            </div>

            <div className={questionAnswerClassNames}>
                <p>{answer}</p>
            </div>
        </div>
    )
}