import './Join.scss'
import joinImage from './images/join.png'
import joinImageMobile from './images/join-mobile.png'
import { vkMetrics, yandexMetrics } from 'src/analytics'

export const Join = ({ openQuestionForm }) => {
    const handleAskQuestionButtonClick = () => {
        openQuestionForm()
        // analytics
        yandexMetrics('btn-question-click')
        vkMetrics('btn-question-click')
    }
    
    return (
        <section id='join' className='join section-mt-100 container container--small scroll-mt'>
            <img className='join__image' src={joinImage} alt='join' />

            <div className='join__container'>
                <div className='join__body'>
                    <div className='join__title title title--light'>
                        <p className='title__sub-text'>еще не с нами?</p>
                        <h2 className='title__main-text'>Вступай в клуб!</h2>
                    </div>

                    {/* <form className='join__form  form'>
                        <input className='input' type='text' placeholder='Имя' />
                        <input className='input' type='text' placeholder='Телефон/Email' />
                    </form> */}

                    <p className='join__body-text'>Свяжись с нашим менеджером, если у тебя остались вопросы.</p>

                    <button onClick={handleAskQuestionButtonClick} className='btn btn--light'>ХОЧУ ЗАДАТЬ ВОПРОС</button>

                    <img className='join__mobile-image' src={joinImageMobile} alt='join' />
                </div>
            </div>
        </section>
    )
}