import { useRef, useState } from 'react'
import { SlideButton } from 'src/components'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, EffectFade } from 'swiper'
import 'swiper/css'
import 'swiper/css/effect-fade'
import './Zones.scss'

import { vkMetrics, yandexMetrics } from 'src/analytics'
import { ZONES_SLIDES as ZONES } from 'src/data'

const AUTO_SLIDE_DELAY = 4000

export const Zones = ({ openGallery }) => {
    const swiperElement = useRef(null)
    const [activeSlideIndex, setActiveSlideIndex] = useState(0)
    const onAutoplayTimeLeft = (s, time, progress) => {
        const selector = '#zones-slide-progress-circle-' + activeSlideIndex
        const progressCircleElement = document.querySelector(selector)
        progressCircleElement?.style?.setProperty('--progress', 1 - progress)
    }

    const handleOnSlideChange = (event) => {
        setActiveSlideIndex(event.realIndex)
    }

    const progressButtons = ZONES.map((currentZone, index) => {
        return (
            <SlideButton
                key={index}
                id={'zones-slide-progress-circle-' + index}
                onClick={() => swiperElement.current?.swiper?.slideToLoop(index)}
                active={AUTO_SLIDE_DELAY && activeSlideIndex === index}
                image={currentZone.preview}
                text={currentZone.formattedName}
            />
        )
    })

    const zonesSlides = ZONES.map((currnetSlide, index) => {
        return (
            <SwiperSlide key={index}>
                <div
                    className='zones__image'
                    style={{ backgroundImage: `url(${currnetSlide.image})` }}
                ></div>
            </SwiperSlide>
        )
    })

    const handleGalleryOpen = () => { 
        openGallery(ZONES[activeSlideIndex].name)
        // analytics
        yandexMetrics('btn-more-click-zone')
        vkMetrics('btn-more-click-zone')
    }

    return (
        <section id='zone' className='zones container container--small scroll-mt'>
            <div className='zones__container'>

                {/* смена изобрежения слайдов */}
                <Swiper
                    id='zones-swiper'
                    className='zones__swiper'
                    ref={swiperElement}
                    slidesPerView='auto'
                    spaceBetween={0}
                    speed={700}
                    effect='fade'
                    autoplay={{
                        delay: AUTO_SLIDE_DELAY,
                        disableOnInteraction: false,
                    }}
                    allowTouchMove={false}
                    onAutoplayTimeLeft={onAutoplayTimeLeft}
                    loop={true}
                    modules={[Autoplay, EffectFade]}
                    onSlideChange={handleOnSlideChange}
                >
                    {zonesSlides}
                </Swiper>

                <div className='zones__grid'>

                    {/* смена названия слайда */}
                    <div className='zones__title title title--light title--large'>
                        <p className='title__sub-text'>ЗОНЫ КЛУБА</p>
                        <h1 key={activeSlideIndex + '-title'} className='title__main-text'>{ZONES[activeSlideIndex].title}</h1>
                    </div>

                    <div key={activeSlideIndex + '-description'} className='zones__transparent-block transparent-block'>
                        <ul className='transparent-block__list'>
                            {ZONES[activeSlideIndex].list.map((element, index) => {
                                return (
                                    <li key={index}>{element}</li>
                                )
                            })}
                        </ul>

                        <button
                            onClick={handleGalleryOpen}
                            className='trasparent-block__btn btn'
                        >
                            Подробнее
                        </button>
                    </div>

                    <div className='zones__btns'>
                        {progressButtons}
                    </div>
                </div>
                
            </div>
        </section>
    )
}