export const validatePhone = (phone) => {
    const digits = phone.replace(/[^\d]/g, '')
    return Boolean(digits.length === 11
        && !digits.startsWith('77')
        && !digits.startsWith('70')
        && !digits.startsWith('71')
        && !digits.startsWith('72')
        && !digits.startsWith('76')
    )
}

export const validateEmail = (email, maxLength = 50) => {
    const emailREGEX = /^(([^<>()[\]\\.,:\s@\"]+(\.[^<>()[\]\\.,:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ //eslint-disable-line
    return emailREGEX.test(email) && email.length <= maxLength
}

export const validateIsPhoneOrEmail = (phoneOrEmail) => {
    if (validateEmail(phoneOrEmail)) {
        return 'email'
    }
    if (validatePhone(phoneOrEmail)) {
        return 'phone'
    }
    return false
}

export const shuffleArray = (array) => {
    let currentIndex = array.length, randomIndex

    // While there remain elements to shuffle.
    while (currentIndex > 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex--

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]]
    }

    return array
}