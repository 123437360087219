import { useEffect, useRef, useState } from 'react'
import { Footer, Header } from 'src/layout'
import { Main } from 'src/pages'

export const App = () => {
    const scrollWatcher = useRef(null)
    const [scrolledY, setScrolledY] = useState(false)

    useEffect(() => {
        const scrollObserver = new IntersectionObserver((entries) => {
            const newValue = !entries[0].isIntersecting
            if (newValue !== scrolledY) {
                setScrolledY(newValue)
            }
        })
        scrollObserver.observe(scrollWatcher.current)
    })

    return (
        <div className='app'>
            <div ref={scrollWatcher} />

            <Header scrolledY={scrolledY} />
            <Main />
            <Footer />
        </div>
    )
}

// ubuntu 400, 500, 700,
// FuturaFuturisC 400,