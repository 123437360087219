import './LeadModal.scss'

export const LeadSent = ({ onClose }) => {
    return (
        <div className='form-modal'>
            <button onClick={onClose} className='form-modal__close-btn'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M15 1L1 15M1 1L15 15" stroke="#656875" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </button>

            <div className='title'>
                <h2 className='title__main-text'>Спасибо, ваша заявка отправлена</h2>
            </div>
            
            <p style={{ color: '#676785', fontFamily: 'Ubuntu', fontSize: 14 }}>Скоро мы с вами свяжемся</p>

            <button
                className='btn'
                onClick={onClose}
            >
                Вернуться на главную
            </button>
        </div>
    )
}