export const PhoneInput = ({ className = 'input', ...props }) => {
    const generateTarget = (value) => {
        return { target: { value, name: props.name, id: props.id } }
    }

    const handleChange = (e) => {
        let current = (typeof e === 'string' ? e : e.target.value).replace(/[^\d]/g, '')

        // console.log(e.target.selectionStart)
        // console.log(e.target.selectionEnd)

        // '' -> ''
        if (!current.length) {
            props.onChange(generateTarget(''))
            return
        }

        // '123' -> '7123'
        if (current.length && (!current.startsWith('7') && !current.startsWith('8') )) {
            current = '7' + current
        }

        // '87' || '77' -> '+7 (9'
        if (current.length && (current.startsWith('77') || current.startsWith('87'))) {
            props.onChange(generateTarget('+7 (9'))
            return
        }
        
        // '7' -> '+7' 
        if (current.length === 1 && current === '7') {
            props.onChange(generateTarget('+7'))
            return
        }

        // '[0-9]' -> '+7 ([0-9]'
        if (current.length === 1 && current !== '7') {
            props.onChange(generateTarget('+7 (' + current))
            return
        }

        // '77' || '87' -> '+7 (9'
        if (current.length === 2 && (current === '77' || current === '87')) {
            props.onChange(generateTarget('+7 (9'))
            return
        }

        // '7123' -> '+7 (123'
        if (current.length >= 2 && current.length <= 4) {
            props.onChange(generateTarget('+7 (' + current.slice(1)))
            return
        }

        // '7123456' -> '+7 (123) 456'
        if (current.length >= 5 && current.length <= 7) {
            props.onChange(generateTarget('+7 (' + current.slice(1, 4) + ') ' + current.slice(4)))
            return
        }

        // '712345678' -> '+7 (123) 456-78'
        if (current.length >= 8 && current.length <= 9) {
            props.onChange(generateTarget('+7 (' + current.slice(1, 4) + ') ' + current.slice(4, 7) + '-' + current.slice(7)))
            return
        }
        
        // '71234567890' -> '+7 (123) 456-78-90'
        if (current.length >= 10 && current.length <= 11) {
            props.onChange(generateTarget('+7 (' + current.slice(1, 4) + ') ' + current.slice(4, 7) + '-' + current.slice(7, 9) + '-' + current.slice(9)))
            return
        }

        // '71234567890123' -> '+7 (123) 456-78-90'
        if (current.length > 11) {
            props.onChange(generateTarget('+7 (' + current.slice(1, 4) + ') ' + current.slice(4, 7) + '-' + current.slice(7, 9) + '-' + current.slice(9, 11)))
            return
        }
        
    }

    const handlePaste = event => {
        event.preventDefault()
        const copiedDigits = event.clipboardData.getData('text').replace(/[^\d]/g, '')

        // if number starts with two 7s and more
        if (copiedDigits.startsWith('77') || copiedDigits.startsWith('87')) {
            // console.log('77__', 9)
            handleChange('9')
            return
        }

        // other
        handleChange(copiedDigits)
    }

    
    return (
        <input
            {...props}
            className={className}
            onPaste={handlePaste}
            onChange={handleChange}
            inputMode='tel'
        />
    )
}
