import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectCreative, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/effect-creative'
import 'swiper/css/pagination'

import './Tariffs.scss'

import lightTariffImage from './images/tarif-light.png'
import infinityTariffImage from './images/tarif-infinity.png'
import smartTariffImage from './images/tarif-smart.png'

import checkedGreenIcon from './images/+_green.png'
import checkedBlueIcon from './images/+_blue.png'
import checkedOrangeIcon from './images/+_orange.png'

import uncheckedIcon from './images/x.png'
import { useEffect, useRef, useState } from 'react'
import { vkMetrics, yandexMetrics } from 'src/analytics'
import { useWindowWidth } from 'src/hooks'

const TARIFFS = [
    {
        name: 'LIGHT',
        color: 'green',
        image: lightTariffImage,
        backgroundColor: '#C7F1F6',
        price: 1700,
        features: [
            { name: 'тренажёрный зал', icon: checkedGreenIcon },
            { name: 'безлимитное посещение групповых программ', icon: uncheckedIcon },
            { name: 'Спа-зона', icon: uncheckedIcon },
            { name: 'доступ во все клубы сети', icon: uncheckedIcon },
            { name: 'доступ для семьи и друзей', icon: uncheckedIcon },
        ]
    },
    {
        name: 'INFINITY',
        color: 'blue',
        image: infinityTariffImage,
        backgroundColor: '#DAD1FF',
        price: 2800,
        features: [
            { name: 'тренажёрный зал', icon: checkedBlueIcon },
            { name: 'безлимитное посещение групповых программ', icon: checkedBlueIcon },
            { name: 'Спа-зона', icon: checkedBlueIcon },
            { name: 'доступ во все клубы сети', icon: checkedBlueIcon },
            { name: 'доступ для семьи и друзей', icon: checkedBlueIcon },
        ]
    },
    {
        name: 'SMART',
        color: 'orange',
        image: smartTariffImage,
        backgroundColor: '#FFDAC8',
        price: 2400,
        features: [
            { name: 'тренажёрный зал', icon: checkedOrangeIcon },
            { name: 'безлимитное посещение групповых программ', icon: checkedOrangeIcon },
            { name: 'Спа-зона', icon: uncheckedIcon },
            { name: 'доступ во все клубы сети', icon: uncheckedIcon },
            { name: 'доступ для семьи и друзей', icon: uncheckedIcon },
        ]
    },
]

const defaultBackgroundColor = '#F1EDFF'

export const Tariffs = ({ openLeadForm }) => {
    const swiperElement = useRef(null)
    const [activeSlideIndex, setActiveSlideIndex] = useState(1)
    const [backgroundColor, setBackgroundColor] = useState(defaultBackgroundColor)

    const windowWidth = useWindowWidth()
    const isMobile = windowWidth <= 1000

    useEffect(() => {
        if (isMobile) {
            setTimeout(() => {
                swiperElement.current?.swiper?.slideTo(1)   
            }, 25)
        }
    }, [isMobile])

    useEffect(() => {
        if (isMobile) {
            setBackgroundColor(TARIFFS[activeSlideIndex]?.backgroundColor)
        }
    }, [isMobile, activeSlideIndex])

    const handleCardClasses = (index, color = 'green') => {
        const cardClassNames = 'tariffs-card'
            + ' tariffs-card--' + color
            + (activeSlideIndex === index ? ' tariffs-card--active' : '')

        return cardClassNames
    }
    
    const handleOnSlideChange = (event) => {
        setActiveSlideIndex(event.realIndex)
    }

    const handleTariffClick = (tariff) => () => { 
        openLeadForm(tariff)

        // аналитика
        yandexMetrics(`btn-buy-click-${tariff}`)
        vkMetrics(`btn-buy-click-${tariff}`)
    }

    const handleSlideChange = (index) => {
        return () => swiperElement.current?.swiper?.slideTo(index)
    }

    const handleMouseEnter = (index) => {
        return () => {
            if (!isMobile) setBackgroundColor(TARIFFS[index]?.backgroundColor)
        }
    }

    const handleMouseLeave = () => {
        if (!isMobile) setBackgroundColor(defaultBackgroundColor)
    }

    const tariffSlides = TARIFFS.map((currentTariff, index) => {
        const tariffFeaturesElement = currentTariff.features.map((currentFeature, index) => {
            return (
                <div
                    key={index}
                    className='tariffs-card__feature'
                >
                    <img src={currentFeature.icon} alt='feature' />
                    <p>{currentFeature.name}</p>
                </div>
            )
        })

        return (
            <SwiperSlide key={index}> 
                <div
                    className={handleCardClasses(index, currentTariff.color)}
                    onClick={handleSlideChange(index)}
                    onMouseEnter={handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                >
                    <div className='tariffs-card__name'>
                        <img src={currentTariff.image} alt='tariffs' />
                        <h3>{currentTariff.name}</h3>
                    </div>

                    <div className='tariffs-card__features'>
                        {tariffFeaturesElement}
                    </div>

                    <p className='tariffs-card__price'>
                        {currentTariff.price} р <span>в месяц</span>
                    </p>

                    <button onClick={handleTariffClick('smart')} className='tariffs-card__btn btn'>оставить заявку</button>
                </div>
            </SwiperSlide>
        )
    })

    return (
        <section id='tariff' className='tariffs section-mt-100 container container--small scroll-mt'>
            <Swiper
                id='tariffs-swiper'
                ref={swiperElement}
                className='tariffs__container'
                direction='horizontal'
                effect='creative'
                modules={[EffectCreative, Pagination]}
                onSlideChange={handleOnSlideChange}
                slidesPerView='auto'
                spaceBetween={20}
                pagination={true}
                breakpoints={{
                    0: {
                        initialSlide: 1,
                        centeredSlides: true,
                        slidesPerView: 'auto',
                        creativeEffect: {
                            limitProgress: 2,
                            prev: {
                                translate: ['-80%', 0, -100],
                                scale: 0.9,
                                opacity: 0.4,
                            },
                            next: {
                                translate: ['80%', 0, -100],
                                scale: 0.9,
                                opacity: 0.4,
                            },
                        }
                    },
                    768: {
                        initialSlide: 1,
                        centeredSlides: true,
                        slidesPerView: 'auto',
                        creativeEffect: {
                            limitProgress: 2,
                            prev: {
                                translate: ['-25vw  ', 0, -100],
                                scale: 0.9,
                                opacity: 0.4,
                            },
                            next: {
                                translate: ['25vw', 0, -100],
                                scale: 0.9,
                                opacity: 0.4,
                            },
                        }
                    },
                    1001: {
                        initialSlide: 1,
                        centeredSlides: false,
                        slidesPerView: '3',
                        creativeEffect: {
                            limitProgress: 3,
                            prev: {
                                translate: ['calc((100% + 20px) * -1)', 0, 0],
                                scale: 1,
                                opacity: 1,
                            },
                            next: {
                                translate: ['calc(100% + 20px)', 0, 0],
                                scale: 1,
                                opacity: 1,
                            },
                        },
                    },
                }}
            >
                {tariffSlides}
                <div
                    className='tariffs__background'
                    style={{ backgroundColor }}
                ></div>
            </Swiper>
        </section>
    )
}