import './ProgressButton.scss'

export const ProgressButton = ({
    active,
    ...props
}) => {
    const buttonClassNames = 'progress' +
        (active ? ' progress--active' : '')

    return (
        <div className={buttonClassNames} {...props}>
            <svg transform='rotate(180)' width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <circle cx='22' cy='22' r='5' fill='white' />
                <rect className='progress__circle' x='2' y='2' width='40' height='40' rx='20' stroke='white'strokeWidth='4' />
                <rect className='progress__progress' x='2' y='2' width='40' height='40' strokeDasharray='130' rx='20' strokeWidth='4' />
            </svg>
        </div>
    )
}