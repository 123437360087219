import { Logo, SocialButton } from 'src/components'
import './Header.scss'
import { useEffect, useRef, useState } from 'react'
import { yandexMetrics } from 'src/analytics'

const MENU_ITEMS = [{ title: 'О нас', link: '#about' }, { title: 'Тарифы', link: '#tariff' }, { title: 'Зоны клуба', link: '#zone' }, { title: 'Контакты', link: '#contact' }, { title: 'FAQ', link: '#faq' }]

export const Header = ({ scrolledY }) => {
    const [menu, setMenu] = useState(false)
    const scrollTop = useRef(null)
    const headerHideTimeout = useRef(null)
    const [hideHeader, setHideHeader] = useState(null)

    const headerActive = scrolledY || menu
    const headerClassNames = 'header'
        + (scrolledY ? ' header--active' : '')
        + (menu ? ' header--menu' : '')
        + (scrolledY && hideHeader ? ' header--slide-up' : ' header--slide-down')

    const toggleMenu = () => setMenu(prevState => !prevState)
    const closeMenu = () => setMenu(false)

    // scroll watcher
    useEffect(() => {
        // const body = document.querySelector('body')
        const handleScroll = () => {
            // const isScrollingUp = scrollTop.current > body.scrollTop
            // const isScrollingDown = scrollTop.current < body.scrollTop
            const isScrollingUp = scrollTop.current > window.scrollY
            const isScrollingDown = scrollTop.current < window.scrollY
            const prevScrollTop = scrollTop.current

            scrollTop.current = window.scrollY
            if (headerHideTimeout.current) {
                clearTimeout(headerHideTimeout.current)

            }

            // ОТКРЫТО МЕНЮ - НЕ СКРЫВАТЬ
            if (menu) {
                setHideHeader(false)
                return
            }
            
            // СКРОЛЛ НАВЕРХ И НЕ СТАРТОВАЯ ПОЗИЦИЯ - СКРЫТЬ ЧЕРЕЗ 2 СЕКУНДЫ
            if (isScrollingUp && prevScrollTop) {
                setHideHeader(false)
                headerHideTimeout.current = setTimeout(() => setHideHeader(true), 2000)
                return
            }

            // СКРОЛЛ ВНИЗ И СКРОЛЛ НИЖЕ 200 ПИСКЕЛЕЙ - СКРЫТЬ ЧЕРЕЗ 2 СЕКУНДЫ
            if (isScrollingDown && prevScrollTop < 200) {
                headerHideTimeout.current = setTimeout(() => setHideHeader(true), 2000)
                return
            }

            // СКРОЛЛ ВНИЗ И СКРОЛЛ БОЛЬШЕ 200 ПИКСЕЛЕЙ - СКРЫТЬ СРАЗУ
            if (isScrollingDown && prevScrollTop >= 200) {
                setHideHeader(true)
                return
            }
        }

        window.addEventListener('scroll', handleScroll)
        
        if (menu) {
            clearTimeout(headerHideTimeout.current)
        }
        
        return () => window.removeEventListener('scroll', handleScroll)
    }, [menu])

    const desktopMenuItems = MENU_ITEMS.map((item, index) => {
        return (
            <li key={index}>
                <a href={item.link}>{item.title}</a>
            </li>
        )
    })

    const mobileMenuItems = MENU_ITEMS.map((item, index) => {
        return (
            <li key={index} onClick={closeMenu}>
                <a href={item.link}>{item.title}</a>
            </li>
        )
    })

    const handlePhoneClickAnalytics = () => {
        yandexMetrics('zvonok')
    }

    return (
        <header className={headerClassNames}>
            <div className='header__container container'>
                <a href='#start' onClick={closeMenu}>
                    <Logo colored={headerActive} header />
                </a>

                <ul className='header__nav'>
                    {desktopMenuItems}
                </ul>

                <a
                    className='header__phone'
                    href='tel:74952555000'
                    onClick={handlePhoneClickAnalytics}
                >+7 (495) 255-50-00</a>

                <button className='header__burger' onClick={toggleMenu}>
                    {menu ?
                        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <rect x='7.41431' y='6.00006' width='16' height='2' rx='1' transform='rotate(45 7.41431 6.00006)'/>
                            <rect x='6' y='17.3138' width='16' height='2' rx='1' transform='rotate(-45 6 17.3138)'/>
                        </svg>
                        :
                        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <rect x='4' y='6' width='16' height='2' rx='1'/>
                            <rect x='4' y='11' width='16' height='2' rx='1'/>
                            <rect x='4' y='16' width='16' height='2' rx='1'/>
                        </svg>
                    }
                </button>
            </div>

            {menu &&
                <div className='header__menu'>
                    <ul className='header__menu-nav'>
                        {mobileMenuItems}
                    </ul>

                    <div className='header__menu-social'>
                        <a target='_blank' rel="noreferrer" href='https://vk.com/ddxfitness'>
                            <SocialButton icon='vk' />
                        </a>

                        <a target='_blank' rel="noreferrer" href='https://t.me/ddxfitness_official'>
                            <SocialButton icon='telegram' />
                        </a>
                        
                        <a target='_blank' rel="noreferrer" href='https://www.youtube.com/channel/UCF8MClm91HIe3k2ieXX3Scg'>
                            <SocialButton icon='youtube' />
                        </a>

                        <a className='social-btn' target='_blank' rel="noreferrer" href='https://dzen.ru/ddx_fitness_ru'>
                            <SocialButton icon='dzen' />
                        </a>
                    </div>
                </div>}
        </header>
    )
}