import { Logo, SocialButton } from 'src/components'
import './Footer.scss'

export const Footer = () => {
    return (
        <footer className='footer container section-mt-100'>
            <div className='footer__row'>
                <Logo />
                <a href='tel:74952555000'>
                    <button className='footer__btn btn btn--light'>+7 (495) 255-50-00</button>
                </a>
                <div className='footer__btns'>
                    <a className='social-btn' target='_blank' rel="noreferrer" href='https://vk.com/ddxfitness'>
                        <SocialButton icon='vk' />
                    </a>

                    <a className='social-btn' target='_blank' rel="noreferrer" href='https://t.me/ddxfitness_official'>
                        <SocialButton icon='telegram' />
                    </a>
                    
                    <a className='social-btn' target='_blank' rel="noreferrer" href='https://www.youtube.com/channel/UCF8MClm91HIe3k2ieXX3Scg'>
                        <SocialButton icon='youtube' />
                    </a>

                    <a className='social-btn' target='_blank' rel="noreferrer" href='https://dzen.ru/ddx_fitness_ru'>
                        <SocialButton icon='dzen' />
                    </a>
                </div>
            </div>

            <div className='footer__row'>
                <p>© 2023 ООО «ИЛОН» | Ilon, LLC</p>
                <a
                    href='https://www.ddxfitness.ru/policy/'
                    target='_blank'
                    rel='noreferrer'
                    className='text-center'
                >
                    Соглашение о защите персональных данных
                </a>
                <a
                    href='https://www.ddxfitness.ru/terms_of_use/'
                    target='_blank'
                    rel='noreferrer'
                    className='text-right'
                >
                    Пользовательское соглашение
                </a>
            </div>
        </footer>
    )
}